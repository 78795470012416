var $class="se2--embedded-chat",$name="embeddedChat",$path="app/partials/embeddedChat/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import appendAsyncScriptElement from 'app/utilities/appendAsyncScriptElement';

import jwtDecode from 'jwt-decode';
import findKey from 'lodash/findKey';

const chatBannerId = 'contact-us-chat';
const contactUsElementIdAttrName = 'contactuselementid';
const accessTokenLocalStorageKey = 'oidc.user';
const accessTokenAttrName = 'accesstoken';
const embeddedChatSessionKey = 'embeddedChatSession';

function getUserObject() {
  const key = findKey(
    localStorage,
    (candidate) => candidate.includes(accessTokenLocalStorageKey),
  );
  return key ? JSON.parse(localStorage.getItem(key)) : null;
}

const isExpired = ({ id_token: idToken }) => Math.round(Date.now() / 1000) < jwtDecode(idToken).exp;

export default shell.registerPartial($this, ({
  addEventListener, configs, log, mount, schedule,
}) => {
  mount((element) => {
    const scriptURL = element.dataset.scriptUrl;
    const userObject = getUserObject();
    if (userObject && userObject.id_token && userObject.access_token && isExpired(userObject)) {
      element.setAttribute(accessTokenAttrName, userObject.access_token);
    }
    element.setAttribute(contactUsElementIdAttrName, chatBannerId);
    schedule.complete(
      () => {
        appendAsyncScriptElement(scriptURL)
          .then(
            () => {
              element.classList.remove(configs.css.classNames.hide);
              addEventListener(element, 'click', () => {
                sessionStorage.setItem(embeddedChatSessionKey, 'true');
              });
            },
            () => {
              log.error('Failed to load script', scriptURL);
            },
          );
      },
    );
  });
});
